export enum OrderHistoryFiltersEnum {
    CANCELLED = 'cancelled',
    DELIVERED = 'delivered',
    IN_PROGRESS = 'in_progress',
    CASH = 'cash',
    ONLINE = 'online'
}

export enum OrderHistorySortingEnum {
    PLACED_DATE_DESC = 'placed_date_desc',
    PLACED_DATE_ASC = 'placed_date_asc',
    PUBLIC_REFERENCE_ASC = 'public_reference_asc',
    PUBLIC_REFERENCE_DESC = 'public_reference_desc',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
    TOTAL_DESC = 'total_desc',
    TOTAL_ASC = 'total_asc',
    ADDRESS_ASC = 'address_asc',
    ADDRESS_DESC = 'address_desc',
    STATUS_ASC = 'status_asc',
    STATUS_DESC = 'status_desc',
    PAYMENT_ASC = 'payment_asc',
    PAYMENT_DESC = 'payment_desc'
}

export type OrderHistorySortingColumn = 'placed_date' | 'public_reference' | 'total' | 'address' | 'status' | 'payment';
export type OrderHistorySortingDirection = 'asc' | 'desc';
