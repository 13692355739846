import React from 'react';

type BellIconProps = {
    fill: string;
};

const BellIcon: React.FC<BellIconProps> = ({ fill }) => {
    return (
        <svg width="18" height="22" viewBox="0 0 18 22" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8594 14.5306L17.2556 13.9269C16.6129 13.2869 16.1033 12.526 15.7563 11.6879C15.4094 10.8499 15.232 9.95139 15.2344 9.04438V8.375C15.2337 7.50479 15.0512 6.64433 14.6986 5.84874C14.346 5.05316 13.8311 4.34002 13.1869 3.755C12.2852 2.95672 11.174 2.433 9.98438 2.24563V0.5H8.01562V2.24563C6.53075 2.52302 5.1909 3.31423 4.23097 4.48055C3.27103 5.64687 2.75227 7.11388 2.76562 8.62438V9.04438C2.76799 9.95139 2.59059 10.8499 2.24366 11.6879C1.89672 12.526 1.38715 13.2869 0.744375 13.9269L0.140625 14.5306V17.2344H4.77375C4.85762 18.2977 5.33925 19.2904 6.12257 20.0144C6.9059 20.7383 7.93335 21.1404 9 21.1404C10.0666 21.1404 11.0941 20.7383 11.8774 20.0144C12.6608 19.2904 13.1424 18.2977 13.2263 17.2344H17.8594V14.5306ZM9 19.2031C8.44814 19.2025 7.91497 19.0032 7.49802 18.6416C7.08106 18.2801 6.80821 17.7806 6.72937 17.2344H11.2706C11.1918 17.7806 10.9189 18.2801 10.502 18.6416C10.085 19.0032 9.55186 19.2025 9 19.2031ZM13.0294 15.2656H2.18812C3.82605 13.6101 4.74157 11.3732 4.73438 9.04438V8.62438C4.7114 7.53634 5.08917 6.47782 5.79582 5.65017C6.50247 4.82252 7.48869 4.28349 8.56688 4.13563C9.16014 4.07507 9.75951 4.13959 10.3263 4.32501C10.8931 4.51043 11.4147 4.81263 11.8575 5.21211C12.3002 5.61159 12.6543 6.09947 12.8969 6.64427C13.1394 7.18906 13.265 7.77866 13.2656 8.375V9.04438C13.2584 11.3732 14.1739 13.6101 15.8119 15.2656H13.0294Z"
                fill={fill}
            />
        </svg>
    );
};

export default BellIcon;
