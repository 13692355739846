import { create } from 'zustand';

type ToasterStoreType = {
    bottomOffset: number;
    actions: {
        setToasterBottomOffset: (value: number) => void;
        resetToasterBottomOffset: () => void;
    };
};

const toasterStore = create<ToasterStoreType>((set) => ({
    bottomOffset: 0,
    actions: {
        setToasterBottomOffset: (value: number) => set({ bottomOffset: value }),
        resetToasterBottomOffset: () => set({ bottomOffset: 0 })
    }
}));

const useToasterBottomOffset = () => toasterStore((state) => state.bottomOffset);
const useToasterStoreActions = () => toasterStore((state) => state.actions);

export { useToasterBottomOffset, useToasterStoreActions };
