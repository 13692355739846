import { Notification } from '../../types/notificationType';
import { Courier } from '../../types/courierType';
import { differenceInMinutes } from 'date-fns';
import i18n from '@lo/shared/localization/i18n';
import { isToday } from 'date-fns';
import { formatTime } from '../formatTime';
import { OutOfStockProductNotification, OutOfStockProduct, OutOfStockProductsNotification } from '../../types/menuType';

const createSubText = (timezone: string, notification: Notification): string => {
    return i18n.t('orders.live_orders_notifications.notification.subText', {
        day: isToday(notification.createdAt)
            ? i18n.t('orders.live_orders_notifications.main.today')
            : i18n.t('orders.live_orders_notifications.main.yesterday'),
        time: formatTime(notification.createdAt, undefined, timezone)
    });
};

const buildCourierDeliveredLastOrderMessage = (timezone: string, notification: Notification): [string, string] => {
    const courier = notification.context as Courier;
    const getBackMinutes = courier.estimatedAtRestaurantTime
        ? differenceInMinutes(courier.estimatedAtRestaurantTime, new Date())
        : 0;

    const mainText = i18n.t('orders.live_orders_notifications.notification.last_order_delivered', {
        courierName: `${courier.name}`,
        getBackMinutes: getBackMinutes < 0 ? 0 : `~${getBackMinutes}`
    });

    const subText = createSubText(timezone, notification);

    return [mainText, subText];
};

const buildProductOutOfStockMessage = (timezone: string, notification: Notification): [string, string] => {
    const context = notification.context as OutOfStockProductNotification;
    const product = context.product as OutOfStockProduct;

    const mainText = product.isSoldOut
        ? i18n.t('orders.live_orders_menu.main.out_of_stock_toaster', {
              product: product.name
          })
        : i18n.t('orders.live_orders_menu.main.in_stock_toaster', {
              product: product.name
          });

    const subText = createSubText(timezone, notification);

    return [mainText, subText];
};

const buildProductsOutOfStockMessage = (timezone: string, notification: Notification): [string, string] => {
    const context = notification.context as OutOfStockProductsNotification;
    const products = context.products;

    const mainText = products[0].is_sold_out
        ? i18n.t('orders.live_orders_menu.main.out_of_stock_toaster', {
              product: products.map((product) => product.name).join(', ')
          })
        : i18n.t('orders.live_orders_menu.main.in_stock_toaster', {
              product: products.map((product) => product.name).join(', ')
          });

    const subText = createSubText(timezone, notification);

    return [mainText, subText];
};

export default function (timezone: string, notification: Notification): [string, string] {
    switch (notification.type) {
        case 'DeliveredLastOrder':
            return buildCourierDeliveredLastOrderMessage(timezone, notification);
        case 'ProductStockUpdate':
            return buildProductOutOfStockMessage(timezone, notification);
        case 'ProductsStockUpdate':
            return buildProductsOutOfStockMessage(timezone, notification);
        default:
            return ['', ''];
    }
}
