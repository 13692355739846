import differenceInSeconds from 'date-fns/differenceInSeconds';
import OrderModel from '../models/OrderModel';

export const isNewOrderNotAccepted = (newOrders: OrderModel[]): boolean | void => {
    if (newOrders.length > 0) {
        return newOrders
            .filter((order) => order.is_new)
            .some((order) => {
                const timePassed = differenceInSeconds(new Date(), order.created_at);
                const difference = timePassed % 120; // get diff every 120s
                return timePassed > 5 && (difference < 5 || difference > 115);
            });
    }
};
