import { useQuery } from '@tanstack/react-query';
import { getNotificationsApi } from '../api/notifications';
import transformEntityTimeToDateObjects from '../helpers/transformEntityTimeToDateObjects';
import { Notification } from '../types/notificationType';
import { compareDesc } from 'date-fns';
import useRestaurant from './useRestaurant';

export default () => {
    const restaurant = useRestaurant();

    const query = useQuery<Notification[]>({
        queryKey: ['notifications', restaurant.id],
        queryFn: getNotificationsApi,
        select: (data) =>
            transformEntityTimeToDateObjects(data).sort((itemA, itemB) => compareDesc(itemA.createdAt, itemB.createdAt)),
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        refetchOnReconnect: 'always'
    });

    const hasUnread = !!query.data?.some((item) => !item.read);
    const amountOfUnread = query.data?.filter((item) => !item.read).length || 0;

    return { ...query, hasUnread, amountOfUnread };
};
