import React, { PropsWithChildren, useEffect, useState } from 'react';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { ADD_TO_STORAGE } from '@lo/shared/constants';
import { useTranslation } from 'react-i18next';
import { useStorageValue } from '../../hooks/useStorageValue';
import classes from './OnboardingBase.module.scss';
import { Button, Checkbox, Modal } from '@jet-pie/react';

type OnboardingBaseProps = PropsWithChildren<{
    id: string;
    title: string;
    actionButtonText: string;
    onActionButtonClick: () => void;
}>;

const OnboardingBase: React.FC<OnboardingBaseProps> = ({ id, title, actionButtonText, onActionButtonClick, children }) => {
    const { t, ready } = useTranslation();
    const [toNotShowAgainChecked, setToNotShowAgainChecked] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const seenOnboardings = useStorageValue('seenOnboardings')?.split(',') || [];
    const showOnboarding = ready && isVisible && !seenOnboardings?.includes(id);

    useEffect(() => {
        defineStorageMethod(ADD_TO_STORAGE, 'isOnboardingVisible', `${showOnboarding}`);
    }, [showOnboarding]);

    const closeOnboarding = (): void => {
        setIsVisible(false);
        if (toNotShowAgainChecked) {
            seenOnboardings.push(id);
            defineStorageMethod(ADD_TO_STORAGE, 'seenOnboardings', [...new Set(seenOnboardings)].join(','));
        }
    };

    const handleActionButtonClick = () => {
        closeOnboarding();
        onActionButtonClick();
    };

    return (
        <Modal title={{ text: title }} size="medium" isOpen={showOnboarding} onClose={closeOnboarding}>
            <div className={classes.container}>
                {children}
                <div className={classes.checkboxContainer}>
                    <Checkbox
                        name="onboarding"
                        value="onboarding"
                        checked={toNotShowAgainChecked}
                        onChange={() => setToNotShowAgainChecked(!toNotShowAgainChecked)}
                        label={
                            t('orders.live_orders_messages.main.do_not_show_this_message_again') ||
                            'Do not show this message again'
                        }
                    />
                </div>
                <div>
                    <Button className={classes.actionButton} onClick={handleActionButtonClick}>
                        {actionButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default OnboardingBase;
