import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { ADD_TO_STORAGE, AVAILABLE_LANGUAGES, AVAILABLE_TENANTS } from '@lo/shared/constants';
import { Location } from 'history';
import { isProduction } from '@lo/shared/helpers/isProduction';
import i18n from '@lo/shared/localization/i18n';

export const handleOrderRequest = (location: Location): void => {
    const publicReference = location.hash.replace('#', '');
    publicReference && defineStorageMethod(ADD_TO_STORAGE, 'orderFromRequest', publicReference);
};

export const handleLanguageParameter = (location: Location): string | null => {
    const locale = new URLSearchParams(location.search).get('kc_locale');
    if (!locale || !AVAILABLE_LANGUAGES[locale]) {
        return null;
    }
    i18n.changeLanguage(locale);

    return locale;
};

export const handleTenant = (location: Location): void => {
    if (isProduction()) {
        let tenant = '';
        for (const [key, value] of Object.entries(AVAILABLE_TENANTS)) {
            if (value === window.location.origin) {
                tenant = key;
            }
        }
        defineStorageMethod(ADD_TO_STORAGE, 'tenant', tenant);
    } else {
        const tenant = new URLSearchParams(location.search).get('tenant');
        tenant && defineStorageMethod(ADD_TO_STORAGE, 'tenant', tenant);
    }
};
