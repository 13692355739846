import { useCallback } from 'react';
import { DefaultError, useQuery } from '@tanstack/react-query';
import { getOrdersApi } from '../api/orders';
import { OrderData } from '../types/orderDataType';
import OrderModel from '../models/OrderModel';
import useRestaurant from './useRestaurant';

export default () => {
    const restaurant = useRestaurant();

    return useQuery<OrderData[], DefaultError, OrderModel[]>({
        queryKey: ['orders', restaurant.id],
        queryFn: getOrdersApi,
        select: useCallback((data: OrderData[]) => data.map((order) => new OrderModel(order)), []),
        staleTime: 1000 * 60 * 60 * 24, // 24 hours
        refetchOnReconnect: 'always'
    });
};
