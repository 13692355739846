import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@jet-pie/react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import useReceipt from '@lo/shared/hooks/useReceipt';
import classes from './Receipt.module.scss';
import { useIsChainAccount } from '@lo/shared/store/auth';

type ParamTypes = {
    id: string;
};

const Receipt: React.FC = () => {
    const { id } = useParams<ParamTypes>();
    const { t } = useTranslation();
    const isChainAccount = useIsChainAccount();
    const { data: receipt } = useReceipt(parseInt(id), true);
    const history = useHistory();

    const printReceipt = () => {
        if (receipt) {
            setTimeout(() => window.print(), 1000);
        }
    };

    useEffect(() => {
        printReceipt();
    }, [receipt]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return isChainAccount ? (
        <Redirect
            to={{
                pathname: `/orders`
            }}
        />
    ) : (
        <Fragment>
            <div className={classes.buttons}>
                <button className={classes.backLink} onClick={history.goBack}>
                    Back to orders
                </button>
                <Button disabled={!receipt} size="small-expressive" onClick={printReceipt}>
                    {t('orders.live_orders_order_details.actions.receipt_print')}
                </Button>
            </div>
            <pre className={classes.content} data-testid="receipt-content" dangerouslySetInnerHTML={{ __html: receipt || '' }} />
        </Fragment>
    );
};

export default Receipt;
