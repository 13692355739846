import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ADD_TO_STORAGE, GET_FROM_STORAGE } from '@lo/shared/constants';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { getBooleanFromString } from '@lo/shared/helpers/string/getBooleanFromString';
import { useWindowSize } from '../../hooks/useWindowSize';
import classes from './CookieBanner.module.scss';

const CookieBanner: React.FC = () => {
    const isAccepted = getBooleanFromString(defineStorageMethod(GET_FROM_STORAGE, 'cookieBannerWasSeen'));

    const [show, setShow] = useState(!isAccepted);
    const { isLessThanTabletWidth } = useWindowSize();
    const { t } = useTranslation();

    const handleClick = () => {
        defineStorageMethod(ADD_TO_STORAGE, 'cookieBannerWasSeen', 'true');
        setShow(false);
    };

    if (!show) {
        return null;
    }

    return (
        <div className={classes.cookieBanner}>
            <p data-testid="cookie-banner-message" className={classes.message}>
                {t('orders.live_orders_terms.cookie_banner.message')}&nbsp;
                <Link className={classes.link} to="/cookie-statement" target="_blank">
                    {t('orders.live_orders_terms.cookie_banner.link')}
                </Link>
            </p>

            <button
                className={classes.button}
                data-testid="cookie-banner-accept-button"
                onClick={handleClick}
                style={{ width: isLessThanTabletWidth ? '100%' : 180 }}
            >
                {t('orders.live_orders_terms.cookie_banner.accept')}
            </button>
        </div>
    );
};

export default CookieBanner;
