import { Listener, storageEvents } from '@lo/shared/helpers/defineStorageMethod';
import { useEffect } from 'react';

export const useStorageListener = (handler: Listener['handler'], fieldKey: string): void => {
    useEffect(() => {
        storageEvents.subscribe(fieldKey, handler);
        return () => {
            storageEvents.unsubscribe(handler);
        };
    }, [handler, fieldKey]);
};
