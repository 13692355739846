import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '@jet-pie/react';
import NotFoundPng from '../../static/images/not-found.png';
import classes from './NotFound.module.scss';

const NotFound: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Fragment>
            <div className={classes.wrapper}>
                <div className={classes.page}>
                    <div className={classes.container}>
                        <img className={classes.img} src={NotFoundPng} alt="logotype" />
                        <div className={classes.content}>
                            <h1 data-testid="not-found-page-heading" className={classes.heading}>
                                {t('orders.live_orders_navigation.page_not_found.heading')}
                            </h1>
                            <p data-testid="not-found-page-message" className={classes.message}>
                                {t('orders.live_orders_navigation.page_not_found.message')}
                            </p>

                            <Button data-testid="not-found-page-link" onClick={() => history.push('/orders')}>
                                {t('orders.live_orders_navigation.page_not_found.link')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default NotFound;
