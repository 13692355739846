import { format, utcToZonedTime } from 'date-fns-tz';

export const formatTime = (utc_datetime: Date | null, outputFormat = 'HH:mm', timezone?: string): string | null => {
    if (!utc_datetime) return null;

    if (timezone) {
        return format(utcToZonedTime(utc_datetime, timezone), outputFormat, { timeZone: timezone });
    } else {
        return format(utc_datetime, outputFormat);
    }
};
