import { useEffect } from 'react';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { isProduction } from '@lo/shared/helpers/isProduction';
import creds from '../../../firebase.json';
import { saveDeviceApi } from '@lo/shared/api/devices';
import config from '@lo/shared/helpers/config';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { ADD_TO_STORAGE, GET_FROM_STORAGE } from '@lo/shared/constants';
import useRestaurant from '@lo/shared/hooks/useRestaurant';

export const useFcm = (): void => {
    const restaurant = useRestaurant();

    const getPermissionIsGranted = async (): Promise<boolean> => {
        if (!('Notification' in window)) return false;

        let permission = Notification.permission;

        if (Notification.permission === 'default') {
            permission = await Notification.requestPermission();
        }

        return permission === 'granted';
    };

    const registerDeviceForFcm = async () => {
        const messagingIsSupported = await isSupported();
        if (!messagingIsSupported) return;

        const permissionIsGranted = await getPermissionIsGranted();
        if (!permissionIsGranted) return;

        try {
            const registration = await navigator.serviceWorker.register(isProduction() ? '/sw.js' : '/sw-dev.js');
            const firebaseApp = initializeApp(isProduction() ? creds.production : creds.development);
            const messaging = getMessaging(firebaseApp);

            const token = await getToken(messaging, {
                vapidKey: config.fcmVapidKey,
                serviceWorkerRegistration: registration
            });

            const tokenInStorage = defineStorageMethod(GET_FROM_STORAGE, 'fcmToken');

            if (tokenInStorage !== token) {
                defineStorageMethod(ADD_TO_STORAGE, 'fcmToken', token);
                saveDeviceApi({ token, os: 'web' });
            }
        } catch (error) {}
    };

    useEffect(() => {
        // Disabling firebase for development env.
        // It doesn't work with .local domain and creates a lot of errors in console
        if (config.env === 'development' || config.env === 'ci' || !('serviceWorker' in navigator)) return;

        registerDeviceForFcm();
    }, [restaurant.id]);
};
