import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { useEffect } from 'react';
import { ADD_TO_STORAGE, GET_FROM_STORAGE } from '@lo/shared/constants';
import useRestaurant from '@lo/shared/hooks/useRestaurant';

export const useSaveRestaurantReference = () => {
    const restaurant = useRestaurant();
    const restaurantReference = restaurant.reference;

    useEffect(() => {
        if (restaurantReference && !defineStorageMethod(GET_FROM_STORAGE, 'selectedRestaurantId')) {
            defineStorageMethod(ADD_TO_STORAGE, 'selectedRestaurantId', restaurantReference);
        }
    }, [restaurantReference]);
};
