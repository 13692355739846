import React, { ReactElement } from 'react';
import classes from './CookieStatement.module.scss';
import { useTranslation } from 'react-i18next';

const CookieStatement: React.FC = (): ReactElement => {
    type ReplacementRule = {
        regex: RegExp;
        replacement: string;
    };

    const { t } = useTranslation();
    const rules: ReplacementRule[] = [];

    // URL's
    rules.push({
        regex: /((?:https:\/\/|www\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$]))/gi,
        replacement: '<a href="$1">$1</a>'
    });

    // Email addresses
    rules.push({
        regex: /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g,
        replacement: '<a href="mailto:$1">$1</a>'
    });

    function getParsedTranslation(translation_key) {
        // 1. Get the translation
        let translation = t(`orders.live_orders_terms.cookie_statement.${translation_key}`);

        // 2. Apply predefined regex replacement rules
        rules.forEach((rule) => {
            translation = translation.replace(rule.regex, rule.replacement);
        });

        // 3. Split translation on every line break and return the right format
        return translation.split('\n\r').map((str, index) => (
            <p key={index}>
                {index > 0 && <br />}
                <span dangerouslySetInnerHTML={{ __html: str }} />
            </p>
        ));
    }

    // Introduction
    const intro_title = getParsedTranslation('intro_title');
    const intro_text = getParsedTranslation('intro_text');

    // Article 1
    const article_one_title = getParsedTranslation('article_one_title');
    const article_one_paragraph_one_text = getParsedTranslation('article_one_paragraph_one_text');
    const article_one_paragraph_two_title = getParsedTranslation('article_one_paragraph_two_title');
    const article_one_paragraph_two_text = getParsedTranslation('article_one_paragraph_two_text');

    // article 2
    const article_two_title = getParsedTranslation('article_two_title');
    const article_two_paragraph_one_text = getParsedTranslation('article_two_paragraph_one_text');
    const article_two_paragraph_two_title = getParsedTranslation('article_two_paragraph_two_title');
    const article_two_paragraph_two_text = getParsedTranslation('article_two_paragraph_two_text');
    const article_two_paragraph_three_title = getParsedTranslation('article_two_paragraph_three_title');
    const article_two_paragraph_three_text = getParsedTranslation('article_two_paragraph_three_text');

    // Article 3
    const article_three_title = getParsedTranslation('article_three_title');
    const article_three_paragraph_one_title = getParsedTranslation('article_three_paragraph_one_title');
    const article_three_paragraph_one_text = getParsedTranslation('article_three_paragraph_one_text');
    const article_three_paragraph_two_title = getParsedTranslation('article_three_paragraph_two_title');
    const article_three_paragraph_two_text = getParsedTranslation('article_three_paragraph_two_text');

    // Article 4
    const article_four_title = getParsedTranslation('article_four_title');
    const article_four_paragraph_one_title = getParsedTranslation('article_four_paragraph_one_title');
    const article_four_paragraph_one_text = getParsedTranslation('article_four_paragraph_one_text');
    const article_four_paragraph_two_title = getParsedTranslation('article_four_paragraph_two_title');
    const article_four_paragraph_two_text = getParsedTranslation('article_four_paragraph_two_text');

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <div className={classes.text} data-testid="cookie-statement-text">
                    {/* Introduction */}
                    <h1 className={classes.header}>{intro_title}</h1>
                    {intro_text}

                    {/* Article 1 */}
                    <h1 className={classes.header}>{article_one_title}</h1>
                    {article_one_paragraph_one_text}
                    <h2 className={classes.subheader}>{article_one_paragraph_two_title}</h2>
                    {article_one_paragraph_two_text}

                    {/* Article 2 */}
                    <h1 className={classes.header}>{article_two_title}</h1>
                    {article_two_paragraph_one_text}
                    <h2 className={classes.subheader}>{article_two_paragraph_two_title}</h2>
                    {article_two_paragraph_two_text}
                    <h2 className={classes.subheader}>{article_two_paragraph_three_title}</h2>
                    {article_two_paragraph_three_text}

                    {/* Article 3 */}
                    <h1 className={classes.header}>{article_three_title}</h1>
                    <h2 className={`${classes.subheader} ${classes.withoutMargin}`}>{article_three_paragraph_one_title}</h2>
                    {article_three_paragraph_one_text}
                    <h2 className={classes.subheader}>{article_three_paragraph_two_title}</h2>
                    {article_three_paragraph_two_text}

                    {/* Article 4 */}
                    <h1 className={classes.header}>{article_four_title}</h1>
                    <h2 className={`${classes.subheader} ${classes.withoutMargin}`}>{article_four_paragraph_one_title}</h2>
                    {article_four_paragraph_one_text}
                    <h2 className={classes.subheader}>{article_four_paragraph_two_title}</h2>
                    {article_four_paragraph_two_text}
                </div>
            </div>
        </div>
    );
};

export default CookieStatement;
