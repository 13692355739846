import { create } from 'zustand';

type AppStatusStore = {
    isActive: boolean;
    hasInternetConnection: boolean;
    actions: {
        setIsActive: (isActive: boolean) => void;
        setHasInternetConnection: (hasInternetConnection: boolean) => void;
    };
};

export const useAppStatusStore = create<AppStatusStore>((set) => ({
    isActive: true,
    hasInternetConnection: true,
    actions: {
        setIsActive: (isActive) => set({ isActive }),
        setHasInternetConnection: (hasInternetConnection) => set({ hasInternetConnection })
    }
}));

/** Hooks */
export const useAppStatusActions = () => useAppStatusStore((state) => state.actions);
