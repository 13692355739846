import React, { FC, ReactNode } from 'react';
import useSingleSignOn from '../hooks/useSingleSignOn';
import { Route, RouteProps } from 'react-router-dom';

const PrivateRoute: FC<RouteProps & { loader?: ReactNode }> = ({ loader, ...props }) => {
    const { isAuthenticated } = useSingleSignOn();

    return isAuthenticated ? <Route {...props} /> : loader;
};

export default PrivateRoute;
