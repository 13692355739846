import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import useNotifications from '@lo/shared/hooks/useNotifications';
import Navigation from '../Navigation/Navigation';
import BellIcon from '../Icons/BellIcon';
import RestaurantLogo from '../RestaurantLogo/RestaurantLogo';
import { useWindowSize } from '../../hooks/useWindowSize';
import openRestaurantPortal from '@lo/shared/helpers/openRestaurantPortal';
import classes from './Header.module.scss';
import useRestaurant from '@lo/shared/hooks/useRestaurant';
import BrandLogo from '../../static/images/icon.svg';
import LinkExternal from '@jet-pie/react/esm/icons/LinkExternal';
import Menu from '@jet-pie/react/esm/icons/Menu';
import { IconButton } from '@jet-pie/react';
import { useOrderNotifications } from '@lo/shared/store/orderNotifications';

type HeaderProps = {
    openMenuSidebar: () => void;
    openNotificationsSidebar: () => void;
    openRestaurantSidebar: () => void;
};

const TakeawayLogo: React.FC = () => (
    <NavLink to="/orders">
        <img src={BrandLogo} className={classes.brandLogo} />
    </NavLink>
);

const Header: React.FC<HeaderProps> = (props) => {
    const { openMenuSidebar, openRestaurantSidebar, openNotificationsSidebar } = props;

    const restaurant = useRestaurant();
    const { t } = useTranslation();
    const { isLessThanDesktopWidth } = useWindowSize();
    const unreadGeneralNotificationsCount = useNotifications().data?.filter((notification) => !notification.read).length || 0;
    const unreadOrdersNotificationsCount = useOrderNotifications().filter(
        (item) => !item.read && item.restaurantReference === restaurant.reference
    ).length;
    const unreadNotificationsCount = unreadOrdersNotificationsCount + unreadGeneralNotificationsCount;

    const handleRestaurantPortalLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        openRestaurantPortal();
    };

    return (
        <header className={classes.container}>
            <div className={classes.leftPart}>
                {isLessThanDesktopWidth ? (
                    <IconButton
                        data-testid="toggle-menu-icon"
                        onClick={openMenuSidebar}
                        size="x-small"
                        variant="ghost"
                        icon={<Menu />}
                    />
                ) : (
                    <TakeawayLogo />
                )}
            </div>

            <div className={classes.centralPart}>{isLessThanDesktopWidth ? <TakeawayLogo /> : <Navigation />}</div>

            <div className={classes.rightPart}>
                <a
                    href="#"
                    onClick={handleRestaurantPortalLinkClick}
                    className={classes.link}
                    data-testid="redirect-dashboard-desktop"
                >
                    {t('orders.live_orders_navigation.menu.restaurant_dashboard')}
                    <LinkExternal className={classes.icon} width={14} height={14} />
                </a>

                <button
                    onClick={openNotificationsSidebar}
                    className={classNames(classes.notificationsButton, { [classes.hasUnread]: unreadNotificationsCount > 0 })}
                    data-testid="toggle-notifications-button"
                >
                    <BellIcon fill={unreadNotificationsCount > 0 ? '#FB6100' : '#8C999B'} />
                    {unreadNotificationsCount > 0 && (
                        <span className={classes.badge} data-testid="notifications-count">
                            {unreadNotificationsCount > 9 ? '9+' : unreadNotificationsCount}
                        </span>
                    )}
                </button>

                <RestaurantLogo restaurant={restaurant} dataTestId="restaurant-logo" onClick={openRestaurantSidebar} />
            </div>
        </header>
    );
};

export default Header;
