import axios from 'axios';
import config from '../helpers/config';
import queryClient from '../services/query/queryClient';
import { RestaurantData } from '../types/restaurantDataType';

export async function saveLogApi(message: string, data: Record<string, any> = {}) {
    const restaurant = queryClient.getQueryData<RestaurantData>(['restaurant']);

    if (restaurant) {
        data.restaurantId = restaurant.id;
    }

    data.datetime = new Date().toUTCString();

    return axios({
        url: `${config.apiUrl}/logs`,
        method: 'post',
        data: { message, data }
    });
}
