import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthStoreActions, useIsAuthenticated } from '@lo/shared/store/auth';
import { login, loginByCode } from '@lo/shared/services/auth';
import { showErrorToast } from '@lo/shared/services/toaster';
import { reloadPage } from '@lo/shared/helpers/reloadPage';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { ADD_TO_STORAGE, GET_FROM_STORAGE } from '@lo/shared/constants';

export default () => {
    const location = useLocation();
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const { t } = useTranslation();
    const actions = useAuthStoreActions();

    const handleError = () => {
        showErrorToast(t('orders.live_orders_messages.main.please_try_later'), {
            autoClose: false,
            ctaButtonText: t('orders.live_orders_messages.main.reload_page'),
            onCtaButtonClick: reloadPage
        });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const keycloakCode = params.get('code');
        const token = params.get('token') || defineStorageMethod(GET_FROM_STORAGE, 'testToken');

        // For smoke tests only
        if (token) {
            history.replace({ search: '' });
            defineStorageMethod(ADD_TO_STORAGE, 'testToken', token);
            actions.loginSuccess(token);
            return;
        }

        if (isAuthenticated && !keycloakCode) {
            return;
        }

        if (keycloakCode) {
            history.replace({ search: '' });
            loginByCode(keycloakCode).catch(handleError);
            return;
        }

        login().catch(handleError);
    }, [isAuthenticated]);

    return { isAuthenticated };
};
