import axios from '@lo/shared/ajax/axiosSetup';
import { DeviceParamsType } from '../types/deviceParamsType';

export function saveDeviceApi(data: DeviceParamsType) {
    return axios({
        url: '/devices/create',
        method: 'post',
        data
    });
}

export function removeDeviceApi(token: string) {
    return axios({
        url: `/devices/${token}`,
        method: 'delete'
    });
}
