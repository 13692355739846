import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';
import { createFeatureManager } from '@justeat/feature-management';
import { FeatureManager } from '@justeat/feature-management/dist/src/featureManager';
import { FmSettings } from '@justeat/feature-management/dist/src/settings';
import { GET_FROM_STORAGE } from '../constants';
import config from '../helpers/config';
import { defineStorageMethod } from '../helpers/defineStorageMethod';
import useRestaurant from '../hooks/useRestaurant';
import { isProduction } from '../helpers/isProduction';
import analytics from '../services/analytics';

const FeatureManagerContext = createContext<FeatureManager | undefined>(undefined);

const getFeatureManagerSettings = (restaurantId: number, country?: string): FmSettings => {
    const restrictionsProviders = new Map();

    if (country) {
        restrictionsProviders.set('Country', () => country);
    }

    return {
        keyPrefix: 'tk-liveorders', // the key prefix - usually your team / component scope
        cdn: {
            host: 'https://features.api.justeattakeaway.com',
            scope: 'tk-liveorders',
            environment: config.env !== 'production' ? 'development' : 'production',
            key: config.jetFmObfuscationKey, // obfuscation key provided with scope
            poll: false
        },
        getContext: () => {
            return {
                restrictionsProviders,
                anonUserId: restaurantId.toString()
            };
        },
        logger: {
            logError: (message: string) => !isProduction() && console.error(message),
            logWarn: (message: string) => !isProduction() && console.warn(message),
            logInfo: (message: string) => !isProduction() && console.log(message)
        },
        onTrack: (key, variantName) => analytics.experimentActive(key, variantName)
    };
};

export const FeatureManagerProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const restaurant = useRestaurant();
    const [featureManager, setFeatureManager] = useState<FeatureManager>();
    const isWeb = typeof document !== 'undefined';

    const loadFeatures = (manager: FeatureManager) => {
        manager
            .loadFromCdn()
            .then(() => setFeatureManager(manager))
            .catch(() => setFeatureManager(undefined));
    };

    useEffect(() => {
        if (config.env === 'ci') return;

        const country = isWeb ? defineStorageMethod(GET_FROM_STORAGE, 'tenant') || undefined : undefined;
        const settings = getFeatureManagerSettings(restaurant.id, country);
        loadFeatures(createFeatureManager(settings));
    }, [restaurant.id]);

    return <FeatureManagerContext.Provider value={featureManager}>{children}</FeatureManagerContext.Provider>;
};

export default FeatureManagerContext;
