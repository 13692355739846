import FlagBulgaria from '@jet-pie/react/esm/icons/Bulgaria';
import FlagDenmark from '@jet-pie/react/esm/icons/Denmark';
import FlagFrance from '@jet-pie/react/esm/icons/France';
import FlagGermany from '@jet-pie/react/esm/icons/Germany';
import FlagItaly from '@jet-pie/react/esm/icons/Italy';
import FlagNetherlands from '@jet-pie/react/esm/icons/Netherlands';
import FlagNorway from '@jet-pie/react/esm/icons/Norway';
import FlagPoland from '@jet-pie/react/esm/icons/Poland';
import FlagPortugal from '@jet-pie/react/esm/icons/Portugal';
import FlagRomania from '@jet-pie/react/esm/icons/Romania';
import FlagUnitedKingdom from '@jet-pie/react/esm/icons/UnitedKingdom';
import FlagSlovakia from '@jet-pie/react/esm/icons/Slovakia';

export const languageFlagsMap = {
    en: FlagUnitedKingdom,
    de: FlagGermany,
    fr: FlagFrance,
    nl: FlagNetherlands,
    it: FlagItaly,
    pl: FlagPoland,
    pt: FlagPortugal,
    bg: FlagBulgaria,
    ro: FlagRomania,
    da: FlagDenmark,
    no: FlagNorway,
    sk: FlagSlovakia
};
