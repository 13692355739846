import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import classes from './Navigation.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import openRestaurantPortal from '@lo/shared/helpers/openRestaurantPortal';
import LinkExternal from '@jet-pie/react/esm/icons/LinkExternal';

const Navigation: React.FC = () => {
    const { isLessThanDesktopWidth } = useWindowSize();
    const { t } = useTranslation();

    const menuItemList = [
        {
            name: 'Live Orders',
            route: '/orders'
        },
        {
            name: t('orders.live_orders_menu.main.heading'),
            route: '/menu'
        },
        {
            name: t('orders.live_orders_navigation.menu.settings'),
            route: '/settings'
        },
        {
            name: t('orders.live_orders_navigation.menu.order_history'),
            route: '/history'
        }
    ];

    const handleRestaurantPortalLinkClick: MouseEventHandler = (e) => {
        e.preventDefault();
        openRestaurantPortal();
    };

    return (
        <div
            data-testid="navigation-container"
            className={classNames(classes.container, {
                [classes.desktop]: !isLessThanDesktopWidth
            })}
        >
            {menuItemList.map((navItem) => (
                <NavLink
                    key={navItem.name}
                    to={navItem.route}
                    className={classes.menuLink}
                    data-testid={`navigate-to${navItem.route.replace('/', '-')}`}
                    activeClassName={classes.active}
                >
                    {t(navItem.name)}
                </NavLink>
            ))}

            {isLessThanDesktopWidth && (
                <a
                    href="#"
                    onClick={handleRestaurantPortalLinkClick}
                    className={classes.menuLink}
                    data-testid="redirect-dashboard"
                >
                    {t('orders.live_orders_navigation.menu.restaurant_dashboard')}
                    <LinkExternal width={14} height={14} />
                </a>
            )}
        </div>
    );
};

export default Navigation;
