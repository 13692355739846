// This library supports both web and mobile platforms
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKey } from './';

const getItem = async (key: StorageKey) => {
    return AsyncStorage.getItem(key);
};

const setItem = async (key: StorageKey, value: string) => {
    return AsyncStorage.setItem(key, value);
};

const removeItem = async (key: StorageKey) => {
    return AsyncStorage.removeItem(key);
};

const multiRemove = async (keys: StorageKey[]) => {
    return AsyncStorage.multiRemove(keys);
};

export default {
    getItem,
    setItem,
    removeItem,
    multiRemove
};
