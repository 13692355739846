import { useQuery } from '@tanstack/react-query';
import { getOrderReceiptApi } from '../api/orders';
import i18n from '../localization/i18n';

export default (id: number, enabled = false) => {
    const lang = i18n.language || 'en';

    return useQuery<string>({
        queryKey: ['receipt', { id, lang }],
        queryFn: () => getOrderReceiptApi(id, lang),
        enabled
    });
};
