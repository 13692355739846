import { ADD_TO_STORAGE, GET_FROM_STORAGE, LOCAL_STORAGE, REMOVE_FROM_STORAGE } from '../constants';
import { isStorageExists } from './isStorageExists';
import { getCookie, removeCookie, setCookie } from './cookie';

export type Listener = { name: string; handler: (value: string | null) => void };
let listeners: Listener[] = [];

export const storageEvents = {
    subscribe: (name: string, handler: Listener['handler']): void => {
        listeners.push({ name, handler });
    },
    unsubscribe: (handler: Listener['handler']): void => {
        listeners = listeners.filter((listener) => listener.handler !== handler);
    }
};

const getFromStorage = (storageType, name) => {
    if (isStorageExists(storageType)) {
        if (storageType === LOCAL_STORAGE) {
            return localStorage.getItem(name);
        } else {
            return sessionStorage.getItem(name);
        }
    } else if (typeof document !== 'undefined') {
        return getCookie(name);
    }
};

const addToStorage = (storageType, name, value): void => {
    if (isStorageExists(storageType)) {
        if (storageType === LOCAL_STORAGE) {
            localStorage.setItem(name, value);
        } else {
            sessionStorage.setItem(name, value);
        }
        const storageListeners = listeners.filter((listener) => listener.name === name);

        if (storageListeners.length > 0) {
            storageListeners.forEach((listener) => listener.handler(value));
        }
    } else if (typeof document !== 'undefined') {
        setCookie(name, value);
    }
};

const removeFromStorage = (storageType, name): void => {
    if (isStorageExists(storageType)) {
        if (storageType === LOCAL_STORAGE) {
            localStorage.removeItem(name);
        } else {
            sessionStorage.removeItem(name);
        }

        const storageListeners = listeners.filter((listener) => listener.name === name);

        if (storageListeners.length > 0) {
            storageListeners.forEach((listener) => listener.handler(null));
        }
    } else if (typeof document !== 'undefined') {
        removeCookie(name);
    }
};

interface DefineStorageMethodFunc {
    (type: typeof GET_FROM_STORAGE, name: string, value?: string | number, storageType?: string): string | null | undefined;
    (type: typeof ADD_TO_STORAGE, name: string, value: string | number, storageType?: string): void;
    (type: typeof REMOVE_FROM_STORAGE, name?: string, value?: string | number, storageType?: string): void;
}

export const defineStorageMethod: DefineStorageMethodFunc = (type, name, value, storageType = LOCAL_STORAGE) => {
    switch (type) {
        case GET_FROM_STORAGE:
            return getFromStorage(storageType, name);
        case ADD_TO_STORAGE:
            addToStorage(storageType, name, value);
            break;
        case REMOVE_FROM_STORAGE:
            removeFromStorage(storageType, name);
            break;
    }
};
