export default <T>(item: T, array: T[], compareField = 'id'): T[] => {
    const index = array.findIndex((a) => a[compareField] === item[compareField]);

    if (index === -1) {
        array.push(item);
    } else {
        array[index] = item;
    }

    return [...array];
};
