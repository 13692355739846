import { OrderModel } from '../../models';
import { NotificationLevel, OrderNotificationType, OrderNotification, Notification } from '../../types/notificationType';
import notificationMessageBuilder from './notificationMessageBuilder';
import i18n from '../../localization/i18n';

export function buildOrderNotification(
    restaurantReference: string,
    key: OrderNotificationType,
    order?: OrderModel
): OrderNotification {
    return {
        id: `${restaurantReference}-${order ? order.public_reference + '-' : ''}${key}`,
        restaurantReference,
        type: key,
        read: false,
        order,
        createdAt: new Date()
    };
}

type NotificationParams = {
    level: NotificationLevel;
    staleTime: number;
    getMessage: () => string;
    getActionTitle: () => string;
    getFullMessage: () => string;
};
const orderNotificationsParams: { [key in OrderNotificationType]: NotificationParams } = {
    AlcoholInfo: {
        level: 'info',
        staleTime: 24,
        getMessage: () => i18n.t('orders.live_orders_notifications.alcohol.message', { beginStrong: '<b>', endStrong: '</b>' }),
        getActionTitle: () => i18n.t('orders.live_orders_notifications.alcohol.action'),
        getFullMessage: () =>
            i18n.t('orders.live_orders_notifications.alcohol.full_message', { beginStrong: '<b>', endStrong: '</b>' })
    },
    PrepareOrder: {
        level: 'warning',
        staleTime: 24,
        getMessage: () =>
            i18n.t('orders.live_orders_notifications.prepare_order.message', { beginStrong: '<b>', endStrong: '</b>' }),
        getActionTitle: () => i18n.t('orders.live_orders_notifications.prepare_order.action'),
        getFullMessage: () =>
            i18n.t('orders.live_orders_notifications.prepare_order.full_message', { beginStrong: '<b>', endStrong: '</b>' })
    }
};

export const isNotificationActive = (key: OrderNotificationType, order: OrderModel) => {
    if (key === 'PrepareOrder' && order.is_in_kitchen) return true;
    return false;
};
export const getNotificationLevel = (key: OrderNotificationType) => orderNotificationsParams[key].level;
export const getNotificationMessage = (key: OrderNotificationType) => orderNotificationsParams[key].getMessage();
export const getNotificationStaleTime = (key: OrderNotificationType) => orderNotificationsParams[key].staleTime;
export const getNotificationActionTitle = (key: OrderNotificationType) => orderNotificationsParams[key].getActionTitle();
export const getNotificationFullMessage = (key: OrderNotificationType) =>
    orderNotificationsParams[key].getFullMessage() || orderNotificationsParams[key].getMessage();

export function prepareOrderNotificationsData(notifications: OrderNotification[], restaurantReference: string) {
    return notifications
        .filter((item) => item.restaurantReference === restaurantReference)
        .map((item) => ({
            id: item.id,
            title: item.order
                ? `#${item.order.public_reference.toUpperCase()}`
                : i18n.t('orders.live_orders_notifications.main.info_title'),
            message: getNotificationFullMessage(item.type),
            type: item.type,
            level: getNotificationLevel(item.type),
            read: item.read,
            createdAt: item.createdAt,
            actionTitle: getNotificationActionTitle(item.type),
            orderId: item.order?.id
        }));
}

export function prepareGeneralNotificationsData(notifications: Notification[], timezone: string) {
    return notifications.map((item) => ({
        id: item.id,
        title: i18n.t('orders.live_orders_notifications.main.info_title'),
        message: notificationMessageBuilder(timezone, item)[0],
        type: item.type,
        level: 'info' as NotificationLevel,
        read: item.read,
        createdAt: item.createdAt
    }));
}
