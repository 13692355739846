import { OrderHistoryDataItem, OrderHistoryFiltersType } from '@lo/shared/types/orderHistoryType';
import { orderStatus } from '../../enums/orderStatusesEnum';
import { OrderHistoryFiltersEnum } from '../../enums/orderHistoryEnums';
import * as locales from 'date-fns/locale';
import { format } from 'date-fns';
import { DateRange, isSameDateRange } from '../dates';
import i18n from '../../localization/i18n';

type CheckFunctionType = (order: OrderHistoryDataItem) => boolean;
type FiltersMap = {
    [Key in OrderHistoryFiltersEnum]: CheckFunctionType;
};

export const orderHistoryStatusFilterOptions = [
    OrderHistoryFiltersEnum.CANCELLED,
    OrderHistoryFiltersEnum.DELIVERED,
    OrderHistoryFiltersEnum.IN_PROGRESS
];
export const orderHistoryPaymentFilterOptions = [OrderHistoryFiltersEnum.CASH, OrderHistoryFiltersEnum.ONLINE];

export const defineOrderHistoryFilter: FiltersMap = {
    [OrderHistoryFiltersEnum.IN_PROGRESS]: (order) => order.status !== orderStatus.DELIVERED && !order.cancelled_at,
    [OrderHistoryFiltersEnum.DELIVERED]: (order) => order.status === orderStatus.DELIVERED && !order.cancelled_at,
    [OrderHistoryFiltersEnum.CANCELLED]: (order) => !!order.cancelled_at,
    [OrderHistoryFiltersEnum.CASH]: (order) => order.payment_type === 'cash',
    [OrderHistoryFiltersEnum.ONLINE]: (order) => order.payment_type !== 'cash'
};

export const filterOrderHistoryItems = (
    data: OrderHistoryDataItem[],
    filters: OrderHistoryFiltersType
): OrderHistoryDataItem[] => {
    if (filters.length === 0) return [...data];
    return [orderHistoryStatusFilterOptions, orderHistoryPaymentFilterOptions].reduce((filteredOrders, filterGroup) => {
        const selectedFilters = filterGroup.filter((filterType) => filters.includes(filterType));
        if (selectedFilters.length === 0) return filteredOrders;
        const checkFunctions = selectedFilters.map((filter) => defineOrderHistoryFilter[filter]);
        return filteredOrders.filter((order) => checkFunctions.some((check) => check && check(order)));
    }, data);
};

export const searchOrderHistoryItems = (data: OrderHistoryDataItem[], searchValue: string): OrderHistoryDataItem[] => {
    if (!searchValue) return data;

    return data.filter((order) => {
        return (
            order.public_reference.toLowerCase().includes(searchValue.toLowerCase()) ||
            order.customer?.full_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            order.customer?.street?.toLowerCase().includes(searchValue.toLowerCase()) ||
            order.customer?.street_number?.toLowerCase().includes(searchValue.toLowerCase())
        );
    });
};

export const filtersTitles = () => {
    return {
        [OrderHistoryFiltersEnum.CANCELLED]: i18n.t('orders.live_orders_order_history.filter_types.cancelled'),
        [OrderHistoryFiltersEnum.IN_PROGRESS]: i18n.t('orders.live_orders_order_history.filter_types.in_progress'),
        [OrderHistoryFiltersEnum.DELIVERED]: i18n.t('orders.live_orders_order_history.filter_types.completed'),
        [OrderHistoryFiltersEnum.CASH]: i18n.t('orders.live_orders_order_history.filter_types.cash'),
        [OrderHistoryFiltersEnum.ONLINE]: i18n.t('orders.live_orders_order_history.filter_types.card')
    };
};

export const getDateRangeLabel = (range: DateRange) =>
    !isSameDateRange(range, 'today') ? `${format(range[0], 'MMM dd, Y')} — ${format(range[1], 'MMM dd, Y')}` : undefined;

export const datesLocaleMap = {
    en: locales.enGB,
    de: locales.de,
    fr: locales.fr,
    nl: locales.nl,
    it: locales.it,
    pl: locales.pl,
    pt: locales.pt,
    bg: locales.bg,
    ro: locales.ro,
    da: locales.da,
    no: locales.nn
};
