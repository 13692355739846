import axios from 'axios';
import AsyncStorage from '@lo/shared/services/storage/async';
import config from '../helpers/config';
import { getAccessToken } from '@lo/shared/services/auth';
import { usePinStore } from '../store/pin';

const axiosSetup = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'application/json'
    }
});

axiosSetup.interceptors.request.use(async (axiosConfig) => {
    const token = await getAccessToken();
    const pinToken = usePinStore.getState().token;
    const selectedRestaurantId = await AsyncStorage.getItem('selectedRestaurantId');
    const tenant = await AsyncStorage.getItem('tenant');

    if (!token) {
        const controller = new AbortController();
        controller.abort();

        return { ...axiosConfig, signal: controller.signal };
    }

    axiosConfig.headers['Authorization'] = `Bearer ${token}`;

    if (pinToken) {
        axiosConfig.headers['X-pin-token'] = pinToken;
    }

    if (selectedRestaurantId) {
        axiosConfig.headers['X-Restaurant-Id'] = selectedRestaurantId;
    }

    if (tenant) {
        axiosConfig.headers['X-Tenant'] = tenant;
    }

    return axiosConfig;
});

export default axiosSetup;
