import { useTranslation } from 'react-i18next';
import { ADD_TO_STORAGE, AVAILABLE_LANGUAGES, GET_FROM_STORAGE } from '@lo/shared/constants';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import useRestaurant from '@lo/shared/hooks/useRestaurant';

const useRestaurantLanguage = (): void => {
    const restaurant = useRestaurant();
    const { i18n } = useTranslation();

    const restaurantLanguage = restaurant.language || 'en';

    if (
        (!defineStorageMethod(GET_FROM_STORAGE, 'orig_lang') ||
            defineStorageMethod(GET_FROM_STORAGE, 'orig_lang') !== restaurantLanguage) &&
        AVAILABLE_LANGUAGES.hasOwnProperty(restaurantLanguage)
    ) {
        defineStorageMethod(ADD_TO_STORAGE, 'orig_lang', restaurantLanguage);
        const lang = defineStorageMethod(GET_FROM_STORAGE, 'lang');
        if (lang) {
            i18n.changeLanguage(lang);
        } else {
            i18n.changeLanguage(restaurantLanguage);
        }
    }
};

export default useRestaurantLanguage;
