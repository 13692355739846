import React, { FC, Suspense, lazy, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateLayout from '../layouts/PrivateLayout/PrivateLayout';
import { useHistory } from 'react-router';
import analytics from '@lo/shared/services/analytics';
import TConnect from '../pages/TConnect/TConnect';
import PrivateRoute from './PrivateRoute';
import { Spinner } from '@jet-pie/react';
import CookieStatement from '../pages/CookieStatement/CookieStatement';
import Receipt from '../pages/Receipt/Receipt';
import NotFound from '../pages/NotFound/NotFound';

const Orders = lazy(() => import('../pages/Orders'));
const Settings = lazy(() => import('../pages/Settings/Settings'));
const Menu = lazy(() => import('../pages/Menu'));
const OrderHistory = lazy(() => import('../pages/OrderHistory'));

const Loader: FC = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner variant="brand" size="XL" />
    </div>
);

export const Routes: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        analytics.viewedPage();
    }, [history.location.pathname]);

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path="/">
                    <Redirect to={`/orders${history.location.search}`} />
                </Route>

                <Route path="/cookie-statement" component={CookieStatement} />
                <Route path="/tconnect" component={TConnect} />

                <PrivateRoute path={['/orders/:id/receipt']} component={Receipt} />

                <PrivateRoute loader={<Loader />} path={['/orders', '/history', '/settings', '/menu']}>
                    <PrivateLayout>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <Route path="/orders" component={Orders} />
                                <Route path="/history" component={OrderHistory} />
                                <Route path="/settings" component={Settings} />
                                <Route path="/menu" component={Menu} />
                            </Switch>
                        </Suspense>
                    </PrivateLayout>
                </PrivateRoute>

                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};
