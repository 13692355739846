import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOrders from '@lo/shared/hooks/useOrders';
import { playSound } from '@lo/shared/helpers/playSound';
import { showInfoToast } from '@lo/shared/services/toaster';
import { isNewOrderNotAccepted } from '@lo/shared/helpers/isNewOrderNotAccepted';
import useRestaurant from '@lo/shared/hooks/useRestaurant';

export default () => {
    const { t } = useTranslation();
    const { data } = useOrders();
    const newOrders = data?.filter((order) => order.is_new) || [];
    const restaurant = useRestaurant();

    useEffect(() => {
        let newOrdersWatcherIntervalId: number | undefined = undefined;

        if (newOrders.length > 0) {
            newOrdersWatcherIntervalId = setInterval(() => {
                if (isNewOrderNotAccepted(newOrders)) {
                    playSound(restaurant.ui_settings.other_notification_sound || 'default');
                    showInfoToast(t('orders.live_orders_messages.main.have_you_accepted_order'), {
                        toastId: 'new-order'
                    });
                }
            }, 10000) as unknown as number;
        }

        return () => {
            clearInterval(newOrdersWatcherIntervalId);
        };
    }, [restaurant, newOrders]);
};
