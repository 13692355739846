import queryClient from '../../query/queryClient';
import notificationMessageBuilder from '../../../helpers/notification/notificationMessageBuilder';
import addOrUpdateItemInArray from '../../../helpers/array/addOrUpdateItemInArray';
import { Notification } from '../../../types/notificationType';
import { markNotificationAsReadApi } from '../../../api/notifications';
import { showInfoToast } from '../../toaster';
import { RestaurantModel } from '../../../models';

export default (restaurant: RestaurantModel, notification: Notification): void => {
    const message = notificationMessageBuilder(restaurant.timezone, notification)[0]; // 0: mainText, 1: subText which is not needed here

    queryClient.setQueryData<Notification[]>(['notifications', restaurant.id], (notifications) => {
        return notifications && addOrUpdateItemInArray(notification, notifications);
    });

    const onClose = () =>
        markNotificationAsReadApi(notification.id).then((newNotification) => {
            queryClient.setQueryData<Notification[]>(['notifications', restaurant.id], (notifications) => {
                return notifications && addOrUpdateItemInArray(newNotification, notifications);
            });
        });

    showInfoToast(message, { onClose });
};
