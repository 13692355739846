// Love typescript with rejecting 'any' as type. If you know a better return type, please adjust so :)
export function getField<Type, Key extends keyof Type>(object: Type, field: string): Key | Date | number | void {
    if (object) {
        const [head, ...rest] = field.split('.');
        return !rest.length ? object[head] : getField(object[head], rest.join('.'));
    }
}

export function setField<EntityType, ValueType>(object: EntityType, field: string, newValue: ValueType): void {
    const [head, ...rest] = field.split('.');

    !rest.length ? (object[head] = newValue) : setField(object[head], rest.join('.'), newValue);
}
