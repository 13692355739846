import React, { useEffect } from 'react';
import classes from './TConnect.module.scss';
import TConnectBackground from '../../static/images/tconnect-page-background.png';
import TConnectIllustration from '../../static/images/tconnect-page-illustration.png';
import { Button } from '@jet-pie/react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks/useWindowSize';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { GET_FROM_STORAGE } from '@lo/shared/constants';

const TConnect: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useWindowSize().isLessThanTabletWidth;
    const { t, i18n } = useTranslation();

    const params = new URLSearchParams(location.search);
    const languageParam = params.get('lang');

    useEffect(() => {
        if (languageParam) {
            i18n.changeLanguage(languageParam);
        } else {
            i18n.changeLanguage(defineStorageMethod(GET_FROM_STORAGE, 'lang') || 'en');
        }
    }, []);

    return (
        <div className={classes.page}>
            <div className={classes.wrapper}>
                {!isMobile && <img src={TConnectBackground} />}
                <div className={classes.content}>
                    <img src={TConnectIllustration} />
                    <p className={classes.headerText}>Live Orders</p>
                    <div className={classes.contentText}>
                        <p>{t('orders.live_orders_navigation.tconnect.header_text_1')}</p>
                        <p>{t('orders.live_orders_navigation.tconnect.header_text_2')}</p>
                        <ul>
                            <li>{t('orders.live_orders_navigation.tconnect.bullet_1')}</li>
                            <li>{t('orders.live_orders_navigation.tconnect.bullet_2')}</li>
                            <li>{t('orders.live_orders_navigation.tconnect.bullet_3')}</li>
                            <li>{t('orders.live_orders_navigation.tconnect.bullet_4')}</li>
                        </ul>
                        <p>{t('orders.live_orders_navigation.tconnect.footer_1')}</p>
                        <p>{t('orders.live_orders_navigation.tconnect.footer_2')}</p>
                    </div>
                    <div>
                        <Button onClick={() => history.push('/orders')}>{t('orders.live_orders_terms.main.ok')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TConnect;
