import { useEffect } from 'react';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { GET_FROM_STORAGE } from '@lo/shared/constants';
import config from '@lo/shared/helpers/config';

export default () => {
    useEffect(() => {
        if (config.env !== 'ci' && window && window.usabilla_live) {
            const lang = defineStorageMethod(GET_FROM_STORAGE, 'lang') || 'en';
            window.usabilla_live('trigger', `live_orders_${lang}`);
        }
    }, []);
};
