import { GET_FROM_STORAGE } from '@lo/shared/constants';
import { defineStorageMethod } from '@lo/shared/helpers/defineStorageMethod';
import { useState, useEffect, useCallback } from 'react';
import { useStorageListener } from './useStorageListener';

export const useStorageValue = (fieldKey: string): string | null => {
    const [value, setValue] = useState<string | null>(defineStorageMethod(GET_FROM_STORAGE, fieldKey) as string);

    useEffect(() => {
        setValue(defineStorageMethod(GET_FROM_STORAGE, fieldKey) as string);
    }, []);

    const storageHandler = useCallback((storageValue) => {
        setValue(storageValue ? storageValue : null);
    }, []);

    useStorageListener(storageHandler, fieldKey);

    return value;
};
