import { Tracker } from './tracker.type';
import { defineStorageMethod } from '../../helpers/defineStorageMethod';
import { GET_FROM_STORAGE } from '../../constants';
import { getBooleanFromString } from '../../helpers/string/getBooleanFromString';
import i18n from '../../localization/i18n';
import config from '../../helpers/config';
import queryClient from '../query/queryClient';
import { RestaurantData } from '../../types/restaurantDataType';
import { OrderData } from '../../types/orderDataType';

type Context = {
    schema: string;
    data: Record<string, any>;
};

const availableCountries = [
    'gb',
    'uk',
    'au',
    'at',
    'be',
    'bg',
    'ca',
    'dk',
    'fr',
    'de',
    'ie',
    'il',
    'it',
    'lu',
    'nl',
    'nz',
    'no',
    'pl',
    'pt',
    'ro',
    'es',
    'ch',
    'us',
    'sk',
    'xx',
    'all'
];

export default class Snowplow implements Tracker {
    private experiments: Record<string, string> = {};

    private consentIsGiven() {
        return getBooleanFromString(defineStorageMethod(GET_FROM_STORAGE, 'cookieBannerWasSeen'));
    }

    private createGlobalContexts() {
        const environment = config.env === 'production' ? 'prod' : config.env === 'staging' ? 'stag' : 'dev';
        const language = i18n.language?.slice(0, 2) || 'en';
        const version = config.release?.slice(0, 36) || '0.0.0'; // Max allowed length of version is 36 characters
        const restaurantData = queryClient.getQueryData<RestaurantData>(['restaurant']);

        let country = restaurantData?.country_contact_information.code || '';
        country = availableCountries.includes(country) ? country : 'xx';

        const contexts: Context[] = [
            {
                schema: 'iglu:jet/cx_platform/jsonschema/1-1-1',
                data: { language, country, version, environment }
            },
            {
                schema: 'iglu:jet/cx_consent/jsonschema/1-0-0',
                data: { status: ['full'] }
            },
            {
                schema: 'iglu:jet/cx_producer/jsonschema/1-0-1',
                data: { team: 'alpha-team' }
            }
        ];

        Object.entries(this.experiments).forEach(([name, variant]) => {
            contexts.push({
                schema: 'iglu:jet/cx_experiment/jsonschema/1-0-0',
                data: {
                    name,
                    variant,
                    platform: 'feature management'
                }
            });
        });

        if (restaurantData) {
            contexts.push({
                schema: 'iglu:jet.part/cx_partner/jsonschema/1-0-0',
                data: {
                    id: restaurantData.reference,
                    status: restaurantData.is_open ? 'open' : 'closed'
                }
            });
        }

        return contexts;
    }

    private createOrderContext(order: OrderData): Context {
        return {
            schema: 'iglu:jet.part/cx_order/jsonschema/1-0-0',
            data: {
                id: order.public_reference,
                total: order.customer_total,
                status: order.status,
                placedDateTime: order.placed_date
            }
        };
    }

    private createProductContext(productId: string, categoryId?: string): Context {
        return {
            schema: 'iglu:jet.part/cx_item/jsonschema/1-0-0',
            data: { id: productId, categoryId: categoryId ?? null }
        };
    }

    private trackSelfDescribingEvent(schema: string, contexts: Context[] = []) {
        if (!this.consentIsGiven()) return;

        window.analytics_pipeline?.('trackSelfDescribingEvent', {
            event: { schema, data: {} },
            context: [...this.createGlobalContexts(), ...contexts]
        });
        window.analytics_pipeline?.('flushBuffer');
    }

    // Global

    viewedPage() {
        if (!this.consentIsGiven()) return;

        window.analytics_pipeline?.('trackPageView', { context: this.createGlobalContexts() });
        window.analytics_pipeline?.('flushBuffer');
    }

    experimentActive(name: string, variant: string) {
        const alreadyExists = this.experiments.hasOwnProperty(name);
        this.experiments[name] = variant;

        if (!this.consentIsGiven()) return;

        if (!alreadyExists) {
            this.trackSelfDescribingEvent('iglu:jet/experiment_active/jsonschema/1-0-0');
        }
    }

    // Orders

    confirmedOrder(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_accept/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    movedOrderToInDelivery(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_onItsWay/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    deliveredOrder(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_done/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    movedOrderBackToKitchen(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_backToKitchen/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    changedOrderDuration(type: 'cook' | 'delivery', amount: number, order: OrderData) {
        const timeContext = {
            schema: 'iglu:jet.part/cx_time/jsonschema/1-0-0',
            data: { type, amount }
        };

        this.trackSelfDescribingEvent('iglu:jet.part/order_timeUpdate/jsonschema/1-0-0', [
            this.createOrderContext(order),
            timeContext
        ]);
    }

    updatedConfirmedOrder(order: OrderData) {
        this.trackSelfDescribingEvent('iglu:jet.part/order_update/jsonschema/1-0-0', [this.createOrderContext(order)]);
    }

    viewedOrderTabsInfo() {
        this.trackSelfDescribingEvent('iglu:jet/dialog_view/jsonschema/1-0-0', [
            {
                schema: 'iglu:jet/cx_component/jsonschema/1-0-1',
                data: { name: 'order_tab_tutorial', type: 'dialog' }
            }
        ]);
    }

    selectedOrdersTab(tab: 'prepare' | 'handover' | 'done') {
        this.trackSelfDescribingEvent('iglu:jet.part/orderTab_select/jsonschema/1-0-0', [
            {
                schema: 'iglu:jet/cx_component/jsonschema/1-0-1',
                data: { name: tab, type: 'header', position: 2 }
            }
        ]);
    }

    openedScheduledOrders() {
        this.trackSelfDescribingEvent('iglu:jet.part/scheduledOrder_select/jsonschema/1-0-0');
    }

    closedScheduledOrders() {
        this.trackSelfDescribingEvent('iglu:jet.part/scheduledOrder_close/jsonschema/1-0-0');
    }

    openedCancelledOrders() {
        this.trackSelfDescribingEvent('iglu:jet.part/cancelledOrder_select/jsonschema/1-0-0');
    }

    closedCancelledOrders() {
        this.trackSelfDescribingEvent('iglu:jet.part/cancelledOrder_close/jsonschema/1-0-0');
    }

    // Menu

    productIsOutOfStock(productId: string, categoryId: string, backToStockAt?: Date) {
        const contexts = [this.createProductContext(productId, categoryId)];

        if (backToStockAt) {
            contexts.push({
                schema: 'iglu:jet.part/cx_response/jsonschema/1-0-1',
                data: { date: backToStockAt }
            });
        }

        this.trackSelfDescribingEvent('iglu:jet.part/item_offline/jsonschema/1-0-0', contexts);
    }

    productIsBackInStock(productId: string, categoryId: string) {
        this.trackSelfDescribingEvent('iglu:jet.part/item_online/jsonschema/1-0-0', [
            this.createProductContext(productId, categoryId)
        ]);
    }

    allProductsAreBackInStock(productIds: string[]) {
        const productContexts = productIds.map((id) => this.createProductContext(id));
        this.trackSelfDescribingEvent('iglu:jet.part/item_allOnline/jsonschema/1-0-0', productContexts);
    }

    scrolledMenuToTop() {
        this.trackSelfDescribingEvent('iglu:jet/navigation_select/jsonschema/1-0-0');
    }

    clickedOnMenuSearch() {
        this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_access/jsonschema/1-0-0');
    }

    clearedMenuSearch(query: string) {
        this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_clear/jsonschema/1-0-0', [
            {
                schema: 'iglu:jet.part/cx_query/jsonschema/1-0-0',
                data: { value: query.length }
            }
        ]);
    }

    clickedOnShowMoreResults(query: string) {
        this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_accessAllResults/jsonschema/1-0-0', [
            {
                schema: 'iglu:jet.part/cx_query/jsonschema/1-0-0',
                data: { value: query.length }
            }
        ]);
    }

    clickedOnMenuSearchListItem(query: string) {
        this.trackSelfDescribingEvent('iglu:jet.part/searchMenu_accessPreliminaryList/jsonschema/1-0-0', [
            {
                schema: 'iglu:jet.part/cx_query/jsonschema/1-0-0',
                data: { value: query.length }
            }
        ]);
    }

    turnedOnOutOfStockFilter() {
        this.trackSelfDescribingEvent('iglu:jet/filter_add/jsonschema/1-0-1', [
            {
                schema: 'iglu:jet/cx_filter/jsonschema/1-0-0',
                data: { name: 'out_of_stock_filter', value: 'on' }
            }
        ]);
    }

    turnedOffOutOfStockFilter(element: 'button' | 'toggle') {
        this.trackSelfDescribingEvent('iglu:jet/filter_remove/jsonschema/1-0-1', [
            {
                schema: 'iglu:jet/cx_filter/jsonschema/1-0-0',
                data: { name: 'out_of_stock_filter', value: 'on' }
            },
            {
                schema: 'iglu:jet/cx_component/jsonschema/1-0-1',
                data: { name: 'out_of_stock_filter', type: element }
            }
        ]);
    }

    selectedMenuCategory(categoryId: string) {
        this.trackSelfDescribingEvent('iglu:jet.part/menuCategory_select/jsonschema/1-0-0', [
            {
                schema: 'iglu:jet/cx_productCategory/jsonschema/1-0-0',
                data: { id: categoryId }
            }
        ]);
    }
}
